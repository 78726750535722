<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-toolbar flat dense color="grey lighten-2">
        <v-toolbar-title>
          {{ title }}
          <span v-if="checklistItemId" class="body-2">
            <i>#{{ checklistItem.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn icon small @click="show = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <validation-provider v-slot="{ errors }" vid="description">
                <v-text-field
                  v-model="checklistItem.description"
                  hide-details="auto"
                  label="Descrição"
                  :disabled="loading"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon v-text="'mdi-content-save'" left />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import checklistItemsApi from '@/api/checklist-items'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    checklistItemId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      loadingSave: false,
      checklistItem: {},
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    title() {
      return this.checklistItemId ? 'Editar' : 'Adicionar'
    },
  },

  watch: {
    show(val) {
      if (!val) return

      this.load()
    },
  },

  methods: {
    async load() {
      try {
        this.checklistItem = {}

        if (!this.checklistItemId) {
          return
        }

        this.loading = true

        const response = await checklistItemsApi.get(this.checklistItemId)

        this.checklistItem = response.data.checklistItem
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },

    async save() {
      try {
        this.loadingSave = true
        this.$refs.observer.reset()

        if (this.checklistItemId) {
          await checklistItemsApi.update(
            this.checklistItemId,
            this.checklistItem,
          )
        } else {
          await checklistItemsApi.insert(this.checklistItem)
        }

        this.show = false
        this.$snackbar.show({
          color: 'success',
          message: this.checklistItemId
            ? this.$messages._('update_success')
            : this.$messages._('new_success'),
        })
        this.$emit('save')
      } catch (e) {
        if (e.response.status === 422) {
          this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingSave = false
      }
    },
  },
}
</script>
